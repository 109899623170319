import { UserCreateModel, UserRole } from "@glideroggan/common";
import { FASTElement, Observable, css, customElement, html, observable, volatile } from "@microsoft/fast-element";
import { ImageUploaderImageData } from "@glideroggan/buttons/image-uploader";

// TODO: modals should always be code splitted, so that we don't need to bundle them in the main bundle
const template = html<AddMemberModal>/*html*/`
<style>
    @import url('assets/css/base.css');
</style>
<div class="card">
    <div class="header">
        <img alt="profile picture" src="${x => x.data.imageUrl}">
    </div>
    <div class="content">
        <form class="form-block">
            <div class="form-group">
                <label for="firstname">Firstname</label>
                <input type="text" class="form-input" id="firstname" name="firstname" required
                    @input="${(x, e) => x.onFirstnameInput(e.event)}">
            </div>
            <div class="form-group">
                <label for="lastname">Lastname</label>
                <input type="text" class="form-input" id="lastname" name="lastname" required
                    value="${x => x.data.lastname}"
                    placeholder="${x => x.data.lastname}"
                    @input="${(x, e) => x.onLastnameInput(e.event)}">
            </div>
            <div class="form-group ${x => x.parentClass}">
                <label for="pass">Password</label>
                <input type="password" class="form-input" id="pass" name="pass"
                    @input="${(x, e) => x.onPasswordInput(e.event)}">
            </div>
            <div class="form-group">
                <label for="birth">Birthday</label>
                <input type="date" class="form-input" id="email" name="birth" required>
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" class="form-input" id="email" name="email" required>
            </div>
            <div class="form-group">
                <label for="role">Role</label>
                <div class="form-group radio">
                    <input type="radio" class="form-radio-input" id="child" name="role"
                        @change="${(x, e) => x.onRoleChanged(e.event)}">
                    <label for="child" class="form-radio-label">Child</label>
                    <input type="radio" class="form-radio-input" id="parent" name="role"
                        @change="${(x, e) => x.onRoleChanged(e.event)}">
                    <label for="parent" class="form-radio-label">Parent</label>
                </div>
            </div>
        </form>
    </div>

    <div class="footer">
        <image-uploader state="${x => x.imageUploadState}" uploadProgress="0">Add Image</image-uploader>
        <button class="button success" ?disabled="${x => !x.isValid}"
            @click="${x => x.add()}">Add</button>
    </div>
</div>
`

// TODO: add tooltip for the inputs, to explain for example birthdate format and why we need it

const styles = css`
.card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .header {

        img {
            width: 200px;
            height: auto;
            aspect-ratio: 1;
        }
    }
    
}
label {
    display: block;
    margin-bottom: 0.5em;

}
.radio {
    display: flex;
    gap: 1em;

}
.form-group {
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus
    input:-webkit-autofill, 
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        color: var(--text-input-color) !important;
}
    &.form-input {
        color: var(--text-input-color) !important;
        &:autofill {
            color: var(--text-input-color) !important;
        }
    }
    margin-bottom: 0.5em;
}
.hidden {
    display: none;
}
// input:-webkit-autofill,
// input:-webkit-autofill:hover, 
// input:-webkit-autofill:focus
// input:-webkit-autofill, 
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   border: 1px solid green;
//   -webkit-text-fill-color: green;
//   -webkit-box-shadow: 0 0 0px 1000px #000 inset;
//   transition: background-color 5000s ease-in-out 0s;
// }
`

@customElement({
    name: 'add-member-modal',
    template,
    styles
})
export class AddMemberModal extends FASTElement {
    @observable ready: boolean = false;
    @observable data: UserCreateModel =
        { firstname: '', lastname: '', role: UserRole.Child, password: '', imageUrl: 'https://www.w3schools.com/howto/img_avatar.png' }
    @observable isValid: boolean = false;
    @observable parentClass: string = 'hidden'
    @observable imageUploadState: 'idle' | 'loading' | 'success' | 'error' = 'idle'
    imageData: ImageUploaderImageData | null = null
    constructor() {
        super();
    }
    async connectedCallback(): Promise<void> {
        super.connectedCallback();

        this.addEventListener('image-uploaded', (e) => this.onImagePrepared(e as CustomEvent<ImageUploaderImageData>))
        // set the radio buttons to the default value
        const childRadio = this.shadowRoot!.querySelector('#child') as HTMLInputElement;
        childRadio.checked = true;
    }
    async onImagePrepared(e: CustomEvent<ImageUploaderImageData>) {
        console.debug('[AddMemberModal] - caught image-uploaded event')
        e.stopPropagation()
        // add image visually
        const eventData = e.detail as ImageUploaderImageData
        this.data.imageUrl = eventData.url
        Observable.notify(this, 'data')

        // keep data for later when sending user
        this.imageData = eventData
    }
    onRoleChanged(event: Event) {
        const target = event.target as HTMLInputElement;
        this.data.role = target.id as UserRole;
        this.isValid = this.validateModel()
        this.parentClass = this.data.role.toString() == 'parent' ? '' : 'hidden'

    }
    reset() {
        const p = this.shadowRoot!.querySelector('input[type="password"]') as HTMLInputElement
        this.data.password = ''
        p.value = ''
    }
    validateModel(): boolean {
        return this.data.firstname.length > 2 && this.data.lastname.length > 2 && this.data.role != undefined
            && (this.data.role == UserRole.Parent ? this.data.password.length > 2 : true)
    }
    onFirstnameInput(event: Event) {
        const target = event.target as HTMLInputElement;
        this.data.firstname = target.value;
        this.isValid = this.validateModel()
    }
    onLastnameInput(event: Event) {
        const target = event.target as HTMLInputElement;
        this.data.lastname = target.value;
        this.isValid = this.validateModel()
    }
    onPasswordInput(event: Event) {
        const target = event.target as HTMLInputElement;
        this.data.password = target.value;
        this.isValid = this.validateModel()
    }
    add() {
        // BUG: somehow we still get a value on the password, so lets just clean it here
        if (this.data.role == UserRole.Child) {
            this.data.password = ''
        }
        this.$emit('add', { data: this.data, imageData: this.imageData })
    }
}


